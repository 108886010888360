<template>
  <div id="app" class="components-layout-demo-basic">
    <a-layout style="min-height: 100vh;background: white">
      <a-layout-header :style="{ zIndex: 1, width: '100%', padding: '0px', textAlign: 'right', background: 'white', position: 'fixed'}">
        <Header/>
      </a-layout-header>
      <a-layout-content :style="{flex: 1,background: '#F7FAFD', marginTop: '64px'}">
        <router-view/>
      </a-layout-content>
      <a-layout-footer :style="{height:'124px', background: 'rgb(98, 50, 170)', width: '100%'}">
        <a-row>
          <span style="cursor: pointer; text-decoration: underline" @click="$router.push('/privacyPolicy')">Privacy Policy</span>
          <span style="margin: 0 10px">|</span>
          <span style="cursor: pointer; text-decoration: underline" @click="$router.push('/terms-of-service')">Terms of Service</span>
          <span style="margin: 0 10px">|</span>
          <span>Phone: +1 （980）785 4555</span>
          <span style="margin: 0 10px">|</span>
          <span>Address: 1900 N Bayshore Dr Suite 1A #136-1754 Miami, Florida, 33132 United States</span>
        </a-row>
        <a-divider></a-divider>
        © 2023 GPT123One. All rights reserved.
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>
import Header from '@/components/header/Header.vue'
export default {
  components: {
    Header
  },
  computed: {

  },
  data() {
    return {

    }
  },
  methods: {

  },
}
</script>

<style>
#app {
  /*font-family: '阿里巴巴普惠体', '仓耳渔阳体 W05', Avenir, Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--default-text-color);
  overflow: auto;
  //height: 100%;
}
.components-layout-demo-basic {
   text-align: center;
 }
.components-layout-demo-basic .ant-layout-header,
.components-layout-demo-basic .ant-layout-footer {
  background: #7dbcea;
  color: #fff;
}
</style>
