// zh.js
export default {
  header: {
    home: '主页',
    download: '下载',
    helpCenter: '帮助',
    signIn: '登录',
    myAccount: '我的账户',
    changePwd: '修改密码',
    signOut: '退出登录'
  },
  tagsView: {
    refresh: '刷新',
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  },
  settings: {
    title: '系统布局配置',
    theme: '主题色',
    tagsView: '开启 Tags-View',
    fixedHeader: '固定 Header',
    sidebarLogo: '侧边栏 Logo'
  }
}
